<template>
  <form style="background-color: #f8f8f8" v-if="value" class="extstatform statistics__extstatform">
    <div
      class="d-flex flex-column flex-lllg-row justify-content-between justify-content-lllg-start"
    >
      <div style="padding-bottom: 2rem" class="set-prt-b mr-4 mr-lg-5">
        <div class="mb-5">
          <h4 class="settings__title--small mb-0">Формат</h4>
          <div class="d-flex justify-content-between flex-row flex-wrap">
            <template v-for="option in options.pr_types.child.children.kind.choices">
              <LeftPointedRadioButton
                :key="`${option.value}-optnPrTpFrm43s`"
                :variant="option.value === value.kind ? 'primary' : 'outline-default'"
                @click="update('kind', option.value)"
                :class="{ error: $v.value.kind.$anyError }"
                class="mr-1 mt-2 fmt-rd h-36"
              >
                {{ option.display_name }}
              </LeftPointedRadioButton>
            </template>
          </div>
        </div>
        <b-form-group
          label-class="ws-nowrap"
          class="mb-4"
          v-if="['1', '4'].includes(this.value.kind)"
        >
          <label for="sec-in" class="settings__title--small" style="margin-top: 0"
            >Продолжительность сторис</label
          >
          <div
            id="sec-in"
            :class="{ error: $v.value.duration.$anyError }"
            class="seconds__input-small"
          >
            <Multiselect
              placeholder="Секунд"
              :show-labels="false"
              :options="[15, 30, 45, 60, 75, 90]"
              :value="value.duration"
              @input="update('duration', Number($event) || 15)"
            />
          </div>
        </b-form-group>
        <div
          style="border-bottom: none"
          class="d-flex flex-column flex-sssm-row justify-content-md-between prc-frm"
        >
          <b-form-group label-class="ws-nowrap" class="fmt-blc mr-3">
            <label
              for="advert-price"
              class="settings__title--small settings__title--small--margin ws-nowrap"
            >
              Цена за рекламу в ₽
            </label>
            <input
              type="number"
              @input="update('uPrice', Number($event.target.value))"
              :value="value.uPrice"
              :class="{ error: $v.value.uPrice.$anyError }"
              class="input settings__input stata__input"
              name="advert-price"
              id="advert-price"
            />
          </b-form-group>
          <b-form-group class="fmt-blc">
            <label for="month-in" class="settings__title--small settings__title--small--margin">
              Набор рекламы на
            </label>
            <Multiselect
              :class="{ error: $v.value.month.$anyError }"
              placeholder="Месяц"
              :show-labels="false"
              :value="currentMonth"
              @input="update('month', $event.value)"
              :options="options.pr_types.child.children.month.choices"
              track-by="value"
              id="month-in"
              class="w-100"
              label="display_name"
            />
          </b-form-group>
        </div>
      </div>
      <div class="set-prt-b ml-lllg-5">
        <div class="mb-5">
          <h4 class="settings__title--small mb-0">Кто может заказать</h4>
          <div class="d-flex justify-content-between flex-row flex-wrap">
            <template v-for="option in options.pr_types.child.children.target.choices">
              <LeftPointedRadioButton
                :key="`${option.value}-o124kirTpFrm43s`"
                :variant="option.value === value.target ? 'primary' : 'outline-default'"
                @click="update('target', option.value)"
                :class="{ error: $v.value.target.$anyError }"
                class="mr-1 mt-2 fmt-rd h-36"
              >
                {{ option.display_name }}
              </LeftPointedRadioButton>
            </template>
          </div>
        </div>
        <h3 class="settings__title--small" style="margin-top: 0">Условия</h3>
        <textarea
          maxlength="150"
          @input="update('content', $event.target.value)"
          :value="value.content"
          name="content"
          placeholder="Например: Подводка + рекламная сторис + макеты"
          class="input settings__textarea stata__textarea w-100"
          :class="{ error: $v.value.content.$error }"
          style="margin-bottom: 20px"
        ></textarea>
        <div class="settings__buttons statistics__settings--buttons">
          <a
            @click="() => $emit('delete', this.value.id)"
            class="settings__title--blue statistics__format--remove"
            >Удалить этот формат</a
          >
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from 'vue-multiselect';
import LeftPointedRadioButton from '@main/components/reusable/elements/buttons/LeftPointedRadioButton.vue';
import { mapState } from 'vuex';
import { required, minLength, between, minValue } from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Object,
    },
    options: Object,
  },
  data: () => ({}),
  validations: {
    value: {
      kind: { required },
      duration: {
        async required(val) {
          return !['1', '4'].includes(this.value.kind) || val;
        },
      },
      uPrice: {
        required,
        minValue: minValue(10),
        async wholeNum(val) {
          return val % 1 === 0;
        },
      },
      month: { required },
      target: { required },
      content: { required, minLength: minLength(1) },
      validationGroup: [
        'value.kind',
        'value.duration',
        'value.uPrice',
        'value.month',
        'value.target',
        'value.content',
      ],
    },
  },
  components: {
    Multiselect,
    LeftPointedRadioButton,
  },
  computed: {
    currentMonth() {
      return this.options.pr_types.child.children.month.choices.filter(
        (val) => val.value === this.value.month
      )[0];
    },
  },
  methods: {
    update(key, value) {
      this.$v.value[key].$touch();
      this.$emit('input', { ...this.value, [key]: value });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect {
  border-radius: 5px !important;
  height: 36px !important;
  min-height: 36px !important;
}
::v-deep .multiselect__tags {
  height: 36px !important;
  min-height: 36px !important;
}

::v-deep .multiselect__single {
  margin-top: 4px !important;
  margin-bottom: 0px !important;
}

.seconds__input-small {
  width: 159.8px;
  border-radius: 5px;
}
.statistics__settings--buttons {
  justify-content: flex-start;
}

.set-prt-b {
  width: 48%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1139px) {
  .set-prt-b {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 400px) {
  .prc-frm {
    max-width: 190px !important;
  }
}
.fmt-rd {
  width: 48% !important;
  min-width: 142px !important;
}
@media (max-width: 380px) {
  .fmt-rd {
    min-width: 136px !important;
    font-size: 13px !important;
  }
}
@media (max-width: 331px) {
  .fmt-rd {
    min-width: 120px !important;
    font-size: 12px !important;
  }
}
.fmt-blc {
  width: 170px;
}
</style>
