<template>
  <div
    v-bind="$attrs"
    style="border-radius: 5px"
    class="settings__info--block flex-grow-0 flex-shrink-1"
  >
    <input
      ref="fileInp"
      class="d-none"
      type="file"
      accept="image/*"
      :title="value || selected ? 'Cкриншот выбран' : 'Загрузите скриншот'"
      @change="(event) => $emit('input', event.target.files[0])"
    />
    <b-button
      style="min-width: 120px !important"
      class="h-36"
      @click="$refs.fileInp.click()"
      :variant="value || selected ? 'outline-default' : 'primary'"
    >
      <div
        class="d-flex align-items-center"
        :class="{ 'justify-content-center': !(value || selected) }"
      >
        <img
          style="width: 17px; height: 17px"
          class="d-inline-block mr-2"
          v-if="value || selected"
          src="@main/assets/img/svg/done_icon.svg"
          alt=""
        />
        <p :style="{ color: value || selected ? 'black' : 'white' }" class="text-center">
          {{ value || selected ? 'Загружено' : 'Загрузить' }}
        </p>
      </div>
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    value: File,
    btnClass: String,
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn__uploaded::before {
  content: '✔';
  position: absolute;
  color: #ffffff;
  background: #6ac259;
  border-radius: 50%;
  top: 24.3%;
  vertical-align: middle;
  text-align: center;
  width: 1.3rem;
  height: 1.3rem;
  margin-left: -23px;
}
.btn__uploaded {
  padding-left: 33px !important;
  width: 120px !important;
}
.file-button {
  height: 36px !important;
  width: 100px;
  border-radius: 5px !important;
}
</style>
